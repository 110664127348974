<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <!-- Content Row -->
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Tên cuộc họp" v-model="meetingRoomInfo.reservationName"
                                  v-validate="'required'" name="reservationName"/>
                        <span class="text-danger text-sm">{{ errors.first('reservationName') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Chọn Phòng họp</label>
                        <v-select class="" v-model="meetingRoomInfo.roomId" :clearable="false"
                                  :options="meetingRoomOptions"
                                  v-validate="'required'" name="meetingRoom">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                        <span class="text-danger text-sm">{{ errors.first('meetingRoom') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Số người tham gia" v-model="meetingRoomInfo.capacity"
                                  name="capacity" type="number" min="0" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Mục đích" v-model="meetingRoomInfo.purpose"
                                  v-validate="'required'" name="purpose"/>
                        <span class="text-danger text-sm">{{ errors.first('purpose') }}</span>
                    </div>

                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Ngày họp</label>
                        <datepicker :format="'dd/MM/yyyy'" v-model="meetingRoomInfo.dateBegin"
                                    :disabledDates="disabledDatePast"
                                    data-vv-validate-on="input" v-validate="'required'" name="dateBegin"></datepicker>
                        <span class="text-danger text-sm">{{ errors.first('dateBegin') }}</span>
                    </div>

                    <div class="vx-col lg:w-1/6 md:w-1/2 w-1/2 mt-2">
                        <label class="vs-input--label">Thời gian bắt đầu</label>
                        <v-select :value="meetingRoomInfo.fromHourString" :clearable="false" :options="timeSpans"
                                  @input="fromHour => updateTimeSpan(meetingRoomInfo, fromHour, 'fromHour')"
                                  name="fromHour"
                                  v-validate="'required'"/>
                        <span class="text-danger text-sm">{{ errors.first('fromHour') }}</span>
                    </div>

                    <div class="vx-col lg:w-1/6 md:w-1/2 w-1/2 mt-2">
                        <label class="vs-input--label">Thời gian kết thúc</label>
                        <v-select :value="meetingRoomInfo.toHourString" :clearable="false" :options="timeSpans"
                                  @input="toHour => updateTimeSpan(meetingRoomInfo, toHour, 'toHour')" name="toHour"
                                  v-validate="'required'"/>
                        <span class="text-danger text-sm">{{ errors.first('toHour') }}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 md:w-1/2 w-1/2 mt-5" style="min-width: 65%">
                        <label class="vs-input--label">Người tham dự</label>
                        <multiselect
                            name="participants"
                            data-vv-validate-on="input"
                            v-model="meetingRoomInfo.participants"
                            :options="employeeOptions"
                            :searchable="true"
                            :multiple="true"
                            :close-on-select="false"
                            :allow-empty="true"
                            placeholder="Chọn người tham dự"
                            label="fullName"
                            track-by="id"
                            v-validate="'required'"
                        >
                        </multiselect>
                        <span class="text-danger text-sm">{{ errors.first('participants') }}</span>
                    </div>
                </div>
                <!-- Save & Reset Button -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="mt-8 flex flex-wrap items-center justify-end mb-5">
                            <vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{
                                    'Tạo mới'
                                }}
                            </vs-button>
                            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="listMeetingRoom">Quay lại
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>

import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import utils from "../../../components/utils";
import Multiselect from 'vue-multiselect';

export default {
    components: {
        vSelect,
        Datepicker,
        Multiselect
    },
    data() {
        return {
            user_data: null,
            activeTab: 0,
            meetingRoomInfo: {},
            meetingRoomList: [],
            timeSpans: [],
            meetingRoomOptions: [],
            selectedMeetingRoomValue: null,
            employeeOptions: [],
            disabledDatePast: {
                customPredictor(date) {
                    if (date.getDate()  < new Date().getDate())  {
                        return true;
                    }
                }
            },
        }
    },
    async created() {
        let timeDuration = 30, timeSpans = [];
        for (let i = 0; i < 24 * 60; i = i + timeDuration) {
            timeSpans.push({value: i, label: utils.minuteToHour(i)});
        }
        this.timeSpans = timeSpans;

        //Get Employee
        this.$vs.loading();
        this.$crm.get('/phonebook').then((response) => {
            this.employeeOptions = response.data.map(item => {
                return {
                    employeeCode: item.employeeCode,
                    fullName: item.fullName,
                    id: item.employeeId,
                }
            });
            this.$vs.loading.close();
        }).catch((err) => {
            this.$vs.loading.close();
            return this.$vs.notify({
                text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            });
        });

        this.$vs.loading();
        this.$crm.get('/meeting-room/get').then((response) => {
            this.meetingRoomOptions = response.data.map(item => {
                return {
                    value: item.id,
                    label: item.name,
                }
            });
            this.$vs.loading.close();
        }).catch((err) => {
            this.$vs.loading.close();
            return this.$vs.notify({
                text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            });
        });
    },
    computed: {

        roomId: {
            get() {
                return {
                    label: this.getMeetingRooms(this.selectedMeetingRoomValue),
                    value: this.selectedMeetingRoomValue
                }
            },
            set(obj) {
                this.selectedMeetingRoomValue = obj.value;
            }
        },
        validateForm() {
            return !this.errors.any()
        }
    },
    methods: {
        getMeetingRooms(id) {
            let meetingRoom = this.meetingRoomOptions.find(x => x.value == id);
            return (meetingRoom && meetingRoom.label) || 'Chưa xác định';
        },
        updateTimeSpan(setting, hour, type) {

            setting[type] = hour.value;
            setting[`${type}String`] = hour.label;
        },
        async saveChanges(evt) {
            evt.preventDefault();
            try {
                if (!this.validateForm) return;
                const result = await this.$validator.validateAll();
                if (!result) return;
                {
                    let dateconver = this.meetingRoomInfo.dateBegin.toLocaleDateString('en-US') + ' ' + this.meetingRoomInfo.fromHourString + ':00';
                    let dateendconver = this.meetingRoomInfo.dateBegin.toLocaleDateString('en-US') + ' ' + this.meetingRoomInfo.toHourString + ':00';
                    let dateBegins = new Date(Date.parse(dateconver));
                    let dateEnds = new Date(Date.parse(dateendconver));
                    let url = `/reservation/book`
                    const payload = {
                        capacity: this.meetingRoomInfo.capacity,
                        dateBegin: dateBegins.toISOString(),
                        dateEnd: dateEnds.toISOString(),
                        employeeId: null,
                        purpose: this.meetingRoomInfo.purpose,
                        reservationName: this.meetingRoomInfo.reservationName,
                        roomId: this.meetingRoomInfo.roomId.value,
                        participants: this.meetingRoomInfo.participants,
                    }
                    this.$vs.loading();
                    this.$crm.post(url, payload).then((res) => {
                        if (res.status == 204) {
                            this.$vs.notify({
                                color: 'danger',
                                text: `Cuộc họp đã bị trùng, vui lòng đặt lại cuộc họp`,
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                            });
                        }
                        if (res.status == 201) {
                            this.$vs.notify({
                                color: 'success',
                                text: `Thêm cuộc họp thành công`,
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                            });
                            this.$router.push(`/user/manage-multipurpose-room`).catch(() => {
                            })
                        }
                        this.$vs.loading.close();

                    }).catch((err) => {
                        this.$vs.loading.close();
                        return this.$vs.notify({
                            text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });
                    });
                }
            } catch (ex) {
                console.log(ex);
            }
        },
        listMeetingRoom() {
            this.$router.push(`/user/meeting-schedule${this.meetingRoomInfo.organizationBranchId ? `?branch_id=${this.meetingRoomInfo.organizationBranchId}` : ''}`).catch(() => {
            })
        }
    },
}
</script>

<style scoped>

</style>
